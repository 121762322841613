import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'amikares';
  currentUser: any | null = null;
  isAdmin: boolean = false;

  constructor(private afAuth: AngularFireAuth) {}

  ngOnInit(): void {
    this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.currentUser = user;
        user.getIdTokenResult().then((idTokenResult: any) => {
          this.isAdmin = !!idTokenResult.claims.admin;
        });
      } else {
        this.currentUser = null;
        this.isAdmin = false;
      }
    });
  }
}
