<div id="layout-wrapper">
    <div *ngIf="showUpdateNotification" class="update-notification" [style.z-index]="99999">
      <span>There is a new version of the app. Please reload the page.</span>
      <button (click)="refreshPage()">Reload</button>
    </div>
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
    <app-sidebar></app-sidebar>
    <p-toast />
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <!-- Main Content -->
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- Footer -->
<!--        <app-footer></app-footer>-->
    </div>
</div>
