import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'mdi mdi-view-dashboard-variant',
    link: '/dashboard',
    isCollapsed: true,
    roles: ['admin', 'agent', 'processor']
  },
  {
    id: 2,
    label: 'MENUITEMS.AGENCY.TEXT',
    isTitle: true,
    roles: ['admin']
  },
  {
    id: 2,
    label: 'MENUITEMS.AGENT.TEXT',
    isTitle: true,
    roles: ['agent']
  },
  {
    id: 2,
    label: 'MENUITEMS.PROCESSOR.TEXT',
    isTitle: true,
    roles: ['processor']
  },
  {
    id: 4,
    label: 'MENUITEMS.POLICIES.TEXT',
    icon: 'mdi mdi-book-lock-outline',
    link: '/policies',
    isCollapsed: true,
    roles: ['admin', 'agent','processor']
  },
  // {
  //   id: 5,
  //   label: 'MENUITEMS.COMMISSIONS.TEXT',
  //   icon: 'mdi mdi-finance',
  //   link: '/commissions',
  //   isCollapsed: true,
  //   adminOnly: true
  // },
  {
    id: 6,
    label: 'MENUITEMS.AGENTS.TEXT',
    icon: 'mdi mdi-briefcase-variant',
    link: '/agents',
    isCollapsed: true,
    roles: ['admin']
  },
  {
    id: 7,
    label: 'MENUITEMS.CONTACTS.TEXT',
    icon: 'mdi mdi-card-account-phone',
    link: '/contacts',
    isCollapsed: true,
    roles: ['admin', 'agent']
  },
  {
    id: 8,
    label: 'MENUITEMS.PROCESSORS.TEXT',
    icon: 'mdi mdi-scanner',
    link: '/processors',
    isCollapsed: true,
    roles: ['admin']
  },
  // {
  //   id: 9,
  //   label: 'MENUITEMS.CALENDAR.TEXT',
  //   icon: 'mdi mdi-calendar-clock',
  //   isCollapsed: true,
  //   adminOnly: true
  // }
];
