import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { LayoutsModule } from "./layouts/layouts.module";
import { ServiceWorkerModule, SwRegistrationOptions } from "@angular/service-worker";
import { PagesModule } from "./pages/pages.module";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { rootReducer } from "./store";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestore, AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/compat/functions";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { SETTINGS as USE_FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { ReactiveFormsModule } from "@angular/forms";
import { MessageService } from "primeng/api";
import { FirebaseAuth } from './shared/services/FirebaseAuth/firebase-auth.service';
import { NgxSpinnerModule } from 'ngx-spinner';


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    // DynamicFormsMaterialUIModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    LayoutsModule,
    PagesModule,
    NgxSpinnerModule,
    StoreModule.forRoot(rootReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AngularFirestore,
    { provide: USE_FIRESTORE_SETTINGS, useValue: { experimentalAutoDetectLongPolling: true } },
    {
      provide: APP_INITIALIZER,
      useFactory: (firebaseAuth: FirebaseAuth) => () => firebaseAuth.initialize(),
      deps: [FirebaseAuth],
      multi: true
    },
    MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
