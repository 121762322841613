import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from "@angular/router";
import { TopbarComponent } from './topbar/topbar.component';
import { NgbCollapseModule, NgbDropdownModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
import { LanguageService } from "../core/services/language.service";
import { SimplebarAngularModule } from "simplebar-angular";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "../shared/shared.module";
import { ToastModule } from "primeng/toast";



@NgModule({
  declarations: [
    LayoutComponent,
    TopbarComponent,
    SidebarComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        NgbNavModule,
        SimplebarAngularModule,
        TranslateModule,
        FormsModule,
        NgbCollapseModule,
        ReactiveFormsModule,
        SharedModule,
        ToastModule
    ],
  providers: [LanguageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutsModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}
